import React from "react"
import { graphql, useStaticQuery } from "gatsby"

export const withAddressesItem = Component => ({ name = "AddressesItem", address, handleDelete, handleEdit, handleSetDefault, loading }) => {
  const { addressesCard } = useStaticQuery(graphql`
    query SANITY_PAGE_ACCOUNT_ADDRESSES_EDIT {
      addressesCard: sanityPageAccountAddresses {
        additionalEditAddressButton
        additionalDeleteAddressButton
        additionalSetDefaultAddressButton
        additionalDefaultBadge
      }
    }
  `)

  const { additionalEditAddressButton, additionalDeleteAddressButton, additionalSetDefaultAddressButton, additionalDefaultBadge } =
    addressesCard || {}

  Component.displayName = name
  return (
    <Component
      address={address}
      handleDelete={handleDelete}
      handleEdit={handleEdit}
      handleSetDefault={handleSetDefault}
      loading={loading}
      additionalEditAddressButton={additionalEditAddressButton}
      additionalDeleteAddressButton={additionalDeleteAddressButton}
      additionalSetDefaultAddressButton={additionalSetDefaultAddressButton}
      additionalDefaultBadge={additionalDefaultBadge}
    />
  )
}
