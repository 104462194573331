import tw, { styled } from "twin.macro"

import { StyledButton } from "../../../Styled/Button"

export const Outer = styled.div`
  ${tw`border-b border-navy border-opacity-20 pt-3 pb-2-4`}
  :first-of-type {
    ${tw`md:border-t`}
  }
`

export const Inner = tw.div`
  flex flex-row justify-between mb-1-2 md:mb-1-6
`

export const Address = tw.div`
  flex flex-col
`

export const Text = tw.p`
  text-14 leading-1.71 tracking-10 uppercase font-bold
`

export const Control = tw.div`
  grid grid-rows-2 gap-y-0-8
`

export const DefaultBadge = tw.p`
  inline text-14 leading-1.42 tracking-5 py-0-4 px-0-6 bg-grey-light
`

export const Button = styled(StyledButton)`
  ${tw`px-2 py-0-8 text-14 leading-1.28 font-bold uppercase`}
`
