import tw, { styled } from "twin.macro"

export const Return = styled.button`
  ${tw`w-full absolute -top-4-8 flex flex-row items-center justify-center md:justify-start`}
  span {
    ${tw`font-medium text-14 leading-1.2 tracking-5 uppercase`}
  }
`

export const Form = tw.form`
  relative border-t border-black pt-3-2 border-opacity-50 md:border-opacity-100
`

export const Header = tw.h2`
  font-bold text-20 leading-1.5 tracking-10 mb-3-2 uppercase
`

export const Control = styled.div`
  ${tw`grid grid-cols-2 gap-x-0-8 mt-3-2`}
  button {
    ${tw`px-1`}
  }
`

export const SuburbPostcodeWrapper = tw.div`
  grid grid-cols-1 md:grid-cols-4 md:gap-x-0-8 mb-0 md:mb-1-6
`

export const Suburb = tw.div`
  col-span-3
`

export const Postcode = tw.div`
  col-span-1
`

export const NameWrapper = tw.div`
  grid grid-cols-2 gap-x-0-8
`
