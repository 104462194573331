import React from "react"

import { withAddresses } from "./withAddresses"
import { AddressesCard } from "./Card/AddressesCard"
import { Layout } from "../Layout/Layout"

export const Addresses = withAddresses(
  ({ uri, paths }): JSX.Element => (
    <Layout uri={uri} paths={paths}>
      <AddressesCard />
    </Layout>
  )
)
