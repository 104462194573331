import React from "react"

import { withAddressesForm } from "./withAddressesForm"
import { TextField } from "../../../TextField/TextField"
import {
  Form,
  Header,
  Control,
  SuburbPostcodeWrapper,
  Suburb,
  Postcode,
  Return,
  NameWrapper,
} from "./AddressFormStyles"
import { Checkbox } from "../../../Checkbox/Checkbox"
import { Icon } from "../../../Icon/Icon"
import { StyledButton } from "../../../Styled/Button"
import { Errors, Error } from "../../Layout/LayoutStyles"
import { Select } from "../../../Select/Select"

export const AddressesForm = withAddressesForm(
  ({
    address,
    handleChange,
    handleSubmit,
    handleCancel,
    loading,
    errors,
    region,
    additionalSetDefaultAddressButton,
    additionalBackToAddressesText,
    additionalEditAddressText,
    additionalFirstNameLabel,
    additionalLastNameLabel,
    additionalAddressOneLabel,
    additionalAddressTwoLabel,
    additionalSuburbLabel,
    additionalStateLabel,
    additionalCountryLabel,
    additionalPostcodeLabel,
    additionalSaveButton,
    additionalCancelButton,
  }): JSX.Element => (
    <Form onSubmit={handleSubmit}>
      <Return
        type={"button"}
        onClick={() => handleCancel()}
        title={additionalBackToAddressesText}
      >
        <Icon name={"arrowLeftSmall"} />
        <span>&nbsp;{additionalBackToAddressesText}</span>
      </Return>
      <Header>{additionalEditAddressText}</Header>
      <NameWrapper>
        <TextField
          name="firstName"
          type="text"
          value={address?.firstName}
          onChange={handleChange}
          label={additionalFirstNameLabel}
          required
          spacing
        />
        <TextField
          name="lastName"
          type="text"
          value={address?.lastName}
          onChange={handleChange}
          label={additionalLastNameLabel}
          required
          spacing
        />
      </NameWrapper>
      <TextField
        name="address1"
        type="text"
        value={address?.address1}
        onChange={handleChange}
        label={additionalAddressOneLabel}
        required
        spacing
      />
      <TextField
        name="address2"
        type="text"
        value={address?.address2}
        onChange={handleChange}
        label={additionalAddressTwoLabel}
        spacing
      />
      <Select
        name="country"
        value={address?.country}
        onChange={handleChange}
        layout={"address"}
        options={region?.countries}
        label={additionalCountryLabel}
        spacing
        required
      />
      <Select
        name="province"
        value={address?.province}
        onChange={handleChange}
        layout={"address"}
        options={region?.provinces}
        label={additionalStateLabel}
        spacing
        required
      />
      <SuburbPostcodeWrapper>
        <Suburb>
          <TextField
            name="city"
            type="text"
            value={address?.city}
            onChange={handleChange}
            label={additionalSuburbLabel}
            required
            spacing
          />
        </Suburb>
        <Postcode>
          <TextField
            name="zip"
            type="text"
            value={address?.zip}
            onChange={handleChange}
            label={additionalPostcodeLabel}
            required
            spacing
          />
        </Postcode>
      </SuburbPostcodeWrapper>
      <Checkbox
        name={"default"}
        checked={address?.default}
        onChange={handleChange}
        label={additionalSetDefaultAddressButton}
      />
      {errors?.length > 0 && (
        <Errors>
          {errors?.map((error, index) => (
            <Error key={index}>
              <p>{error?.message}</p>
            </Error>
          ))}
        </Errors>
      )}
      <Control>
        <StyledButton
          wide="true"
          type="submit"
          size="primary"
          disabled={loading}
        >
          {additionalSaveButton}
        </StyledButton>
        <StyledButton
          type="button"
          wide="true"
          size="primary"
          onClick={() => handleCancel()}
          disabled={loading}
          colour="navyBorderedWithNavyText"
        >
          {additionalCancelButton}
        </StyledButton>
      </Control>
    </Form>
  ),
)
