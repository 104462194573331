import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { CountryRegionData } from "react-country-region-selector"

export const withAddressesForm = Component => ({
  name = "AddressesForm",
  address,
  handleChange,
  handleSubmit,
  handleCancel,
  loading,
  errors,
}) => {
  const { addressesForm } = useStaticQuery(graphql`
    query SANITY_PAGE_ACCOUNT_ADDRESSES_FORM {
      addressesForm: sanityPageAccountAddresses {
        additionalSetDefaultAddressButton
        additionalBackToAddressesText
        additionalEditAddressText
        additionalFirstNameLabel
        additionalLastNameLabel
        additionalAddressOneLabel
        additionalAddressTwoLabel
        additionalSuburbLabel
        additionalStateLabel
        additionalCountryLabel
        additionalPostcodeLabel
        additionalSaveButton
        additionalCancelButton
      }
    }
  `)

  const {
    additionalSetDefaultAddressButton,
    additionalBackToAddressesText,
    additionalEditAddressText,
    additionalFirstNameLabel,
    additionalLastNameLabel,
    additionalAddressOneLabel,
    additionalAddressTwoLabel,
    additionalSuburbLabel,
    additionalStateLabel,
    additionalCountryLabel,
    additionalPostcodeLabel,
    additionalSaveButton,
    additionalCancelButton,
  } = addressesForm || {}

  const region = {
    countries: CountryRegionData.map(region => ({
      label: region[0],
      value: region[0],
    })),
    provinces: address?.country
      ? CountryRegionData.find(
          region =>
            region[0]?.toLowerCase().trim() ===
            address?.country?.toLowerCase().trim(),
        )?.[2]
          ?.split(`|`)
          ?.map(region => ({
            label: region.split(`~`)?.[0],
            value: region.split(`~`)?.[0],
          }))
      : [],
  }

  Component.displayName = name
  return (
    <Component
      address={address}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      handleCancel={handleCancel}
      loading={loading}
      errors={errors}
      region={region}
      additionalSetDefaultAddressButton={additionalSetDefaultAddressButton}
      additionalBackToAddressesText={additionalBackToAddressesText}
      additionalEditAddressText={additionalEditAddressText}
      additionalFirstNameLabel={additionalFirstNameLabel}
      additionalLastNameLabel={additionalLastNameLabel}
      additionalAddressOneLabel={additionalAddressOneLabel}
      additionalAddressTwoLabel={additionalAddressTwoLabel}
      additionalSuburbLabel={additionalSuburbLabel}
      additionalStateLabel={additionalStateLabel}
      additionalCountryLabel={additionalCountryLabel}
      additionalPostcodeLabel={additionalPostcodeLabel}
      additionalSaveButton={additionalSaveButton}
      additionalCancelButton={additionalCancelButton}
    />
  )
}
