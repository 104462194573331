import React from "react"

import { useApp } from "../../../hooks/useApp"

export const withAddresses = Component => ({
  name = "Addresses",
  page,
  uri,
}) => {
  const { globalState } = useApp()

  const { title } = page || {}

  const [{ addressFormActive }] = globalState

  const paths = addressFormActive
    ? [
        {
          title,
        },
        {
          title: "edit address",
        },
      ]
    : [
        {
          title,
        },
      ]

  Component.displayName = name
  return <Component uri={uri} paths={paths} />
}
