import React, { useEffect, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"

import { useApp } from "../../../../hooks/useApp"
import { useCustomerAddress } from "../../../../hooks/useCustomer"

export const withAddressesCard = Component => ({ name = "Addresses" }) => {
  const {
    addresses,
    address,
    setAddress,
    createAddress,
    defaultAddress,
    updateAddress,
    deleteAddress,
    initialData,
    loading,
    saving,
    errors,
  } = useCustomerAddress()

  const { accountAddresses } = useStaticQuery(graphql`
    query SANITY_PAGE_ACCOUNT_ADDRESSES_CARD {
      accountAddresses: sanityPageAccountAddresses {
        additionalLoadingText
        additionalNoAddressText
      }
    }
  `)

  const { additionalLoadingText, additionalNoAddressText } =
    accountAddresses || {}

  const sortedAddress = [...addresses].sort(a => (a.default ? -1 : 1))

  const { globalState } = useApp()

  const [isNewDefault, setIsNewDefault] = useState(false)

  const [{ addressFormActive, newAddressId }, dispatch] = globalState

  useEffect(() => {
    if (
      (address?.action === "update" || address?.action === "create") &&
      !addressFormActive
    ) {
      dispatch({
        type: "SHOW_ADDRESS_FORM",
      })
    }
  }, [address?.action, addressFormActive, dispatch])

  useEffect(() => {
    if (!address?.action && addressFormActive) {
      dispatch({
        type: "HIDE_ADDRESS_FORM",
      })
    }
  }, [address?.action, addressFormActive, dispatch])

  useEffect(() => {
    if (isNewDefault && newAddressId) {
      defaultAddress(newAddressId)
      setIsNewDefault(false)
    }
  }, [isNewDefault, defaultAddress, newAddressId])

  const handleSubmit = async event => {
    event.preventDefault()
    if (address?.action === `update`) {
      await updateAddress(address?.id, address)
      if (address?.default === true) {
        await defaultAddress(address?.id)
      }
    }
    if (address.action === `create`) {
      await createAddress(address)
      if (address?.default === true) {
        setIsNewDefault(true)
      }
    }
  }

  const handleChange = ({ target: { type, name, value, checked } }) =>
    setAddress(prevState => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }))

  const handleEdit = id =>
    setAddress({
      action: "update",
      ...addresses?.filter(address => address?.id === id)[0],
    })

  const handleAdd = () => setAddress({ ...initialData, action: "create" })

  const handleCancel = () => setAddress({ ...initialData })

  const handleDelete = async id => await deleteAddress(id)

  const handleSetDefault = async id => await defaultAddress(id)

  Component.displayName = name
  return (
    <Component
      address={address}
      addresses={sortedAddress}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      handleAdd={handleAdd}
      handleEdit={handleEdit}
      handleDelete={handleDelete}
      handleCancel={handleCancel}
      handleSetDefault={handleSetDefault}
      loading={loading || saving}
      errors={errors}
      additionalLoadingText={additionalLoadingText}
      additionalNoAddressText={additionalNoAddressText}
    />
  )
}
